// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';

export const JSSThemeHeaderMenu61300: JSSThemeDefaultHeaderMenuType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { white, primary, background1 },
    },
  } = themeVars;
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        color: white,
        fontWeight: 'bold',
        padding: [0, margins.lg],
        '&:hover, &:focus': {
          color: primary,
        },
      },
      menuItemActive: {
        color: primary,
      },
    },
    HeaderMenuMobile: {
      mobileMenu: {
        background: background1,
        flexWrap: 'wrap',
      },
      menuItem: {
        borderBottom: `3px solid ${background1}`,
        '&:hover, &:focus': {
          color: white,
        },
        '&.active': {
          color: primary,
        },
      },
      // menuItemActive: {
      //   background: text3,
      //   borderBottom: `3px solid ${primary}`,
      // },
    },
  };
};
