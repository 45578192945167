// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues61300: JSSThemeDefaultPopularLeaguesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background1, primary },
    },
    fontSizes,
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background1,
        '@media (pointer: coarse)': {
          background: background1,
        },
      },
      header: {
        color: primary,
        fontSize: fontSizes.xxl,
      },
      link: {
        '&:hover': {
          background: '#9700ba',
          filter: 'invert(1)',
        },
      },
    },
  };
};
