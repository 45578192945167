// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive61300: JSSThemeDefaultLiveType = themeVars => {
  const {
    colors: {
      colorTypes: { background3, white, black },
    },
  } = themeVars;
  return {
    LiveMenuEvent: {
      LiveEventRanked: {
        background: background3,
      },
      LiveEventRankedDesktop: {
        background: '#0a1a28 !important',
      },
      LiveIcon: {
        fill: white,
      },
    },
    LiveStreamingMessage: {
      loginButton: {
        color: black,
        '&:hover': {
          color: black,
          background: '#03db01',
        },
      },
    },
    LiveMenuEventHeading: {
      LiveIcon: {
        fill: white,
      },
    },
    LiveMenuRankedEvent: {
      LiveIcon: {
        fill: white,
      },
    },
  };
};
