import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu61300: JSSThemeDefaultSportsMenuType = themeVars => {
  const {
    margins,
    colors: {
      //colorCombinations: {  },
      colorTypes: { white, text4, primary, black },
    },
  } = themeVars;
  return {
    SportsMenuDesktop: {
      header: {
        color: primary,
      },
      region: {
        '&:hover, &.active': {
          color: black,
        },
      },
      sport: {
        color: white,
        '&:hover, &.active': {
          color: black,
        },
        'svg.svg-inline--fa': {
          color: primary,
        },
      },
    },
    HorizontalSportsListUISmallIcons: {
      list: {
        paddingTop: 10,
        background: 'transparent',
      },
      sportLink: {
        color: text4,
        '&.active, &:hover': {
          color: white,
        },
      },
    },
    HorizontalSportsListIconSmall: {
      sportName: {
        fontWeight: 'bold',
      },
    },
    HorizontalSportsTabs: {
      rootSmallIcons: {
        height: 40,
        margin: 0,
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: 'transparent',
      },
      sportLink: {
        padding: ['0', margins.md, margins.xs],
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        color: primary,
        background: black,
      },
      title: {
        color: white,
        fontWeight: 'bold',
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
      },
      sportName: {
        fontWeight: 'bold',
      },
    },
    SubMenuListUIMobile: {
      link: {
        color: white,
        '&:hover,&:focus,&.active': {
          color: black,
          background: primary,
        },
      },
    },
  };
};
