// @flow
import { type JSSThemeDefaultDropdownMenuType } from '../JSSThemeDefaultDropdownMenu';

export const JSSThemeDropdownMenu61300: JSSThemeDefaultDropdownMenuType = themeVars => {
  const {
    colors: {
      colorTypes: { black },
    },
  } = themeVars;
  return {
    dropdownDesktop: {
      item: {
        '&:hover,&.active': { color: black },
      },
    },
  };
};

