// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61300,
  JSSThemeVariables61200,
  getJSSThemePatterns61200,
} from './JSSTheme61300';
import { GeoblockPage61300 } from './GeoblockPage61300';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop61300')).FullDesktop61300,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile61300')).FullMobile61300,
  }),
  JSSTheme: JSSTheme61300,
  JSSThemeVariables: JSSThemeVariables61200,
  JSSThemePatterns: getJSSThemePatterns61200,
  GeoblockPage: GeoblockPage61300,
});
