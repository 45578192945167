// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  type ColorTypes,
  getColorCombinations,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop, getButtonColors } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip61300';
import { JSSThemeHeaderMenu61300 } from './JSSThemeHeaderMenu61300';
import { JSSThemeMyBets61300 } from './JSSThemeMyBets61300';
import { JSSThemeBannerItems61300 } from './JSSThemeBannerItems61300';
import { JSSThemeSportsMenu61300 } from './JSSThemeSportsMenu61300';
import { JSSThemeForm61300 } from './JSSThemeForm61300';
import { JSSThemeTooltip61300 } from './JSSThemeTooltip61300';
import { JSSThemeBonuses61300 } from './JSSThemeBonuses61300';
import { JSSThemeHeaderMobile61300 } from './JSSThemeHeaderMobile61300';
import { JSSThemeSearch61300 } from './JSSThemeSearch61300';
import { JSSThemeEventSlider61300 } from './JSSThemeEventSlider61300';
import { JSSThemeOutcomes61300 } from './JSSThemeOutcomes61300';
import { JSSThemeCoupons61300 } from './JSSThemeCoupons61300';
import { JSSThemeHeader61300 } from './JSSThemeHeader61300';
import { JSSThemeBalance61300 } from './JSSThemeBalance61300';
import { JSSThemeAuthForm61300 } from './JSSThemeAuthForm61300';
import { JSSThemePopularLeagues61300 } from './JSSThemePopularLeagues61300';
import { JSSThemeAccountSection61300 } from './JSSThemeAccountSection61300';
import { JSSTheme61200Resets } from './JSSTheme61200Resets';
import { JSSThemeLive61300 } from './JSSThemeLive61300';
import { JSSThemeCasino61300 } from './JSSThemeCasino61300';
import { JSSThemePromotions61300 } from './JSSThemePromotions61300';
import { JSSThemeSitemap61300 } from './JSSThemeSitemap61300';
import { JSSThemeFooterAll61300 } from './JSSThemeFooterAll61300';
import { JSSThemeDropdownMenu61300 } from './JSSThemeDropdownMenu61300';
// import { JSSThemeAffiliates61300 } from './JSSThemeAffiliates61300';
import { JSSThemeCookies61300 } from './JSSThemeCookies61300';

export const colorTypes61200: ColorTypes = {
  ...colorTypes,
  primary: '#04ff01',
  text1: '#494949',
  text2: '#bfbfbf',
  text3: '#203552',
  text4: 'rgba(255, 255, 255, 0.5)',
  text5: '#03db01',
  background1: '#131313',
  background2: '#1e4e07',
  background3: '#112840',
  background4: '#999',
  background5: '#0f3b86',
  background6: '#204064', //new color
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#0f3b86',
  success: '#21a22d',
  error: '#ff3434',
  error2: '#ff3434',
  error3: '#ff3434',
  warning: 'orange',
  inform: '#830202',
  neutral1: '#2B456B',
  neutral2: '#5c0101',
  neutral3: '#4e8235',
  neutral4: '#1f3d63', //available for a new color
  border1: '#545e66',
  border2: '#0d1f2d',
};

const pick = ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
}) => ({
  primaryWhite,
  whitePrimary,
  text2Primary,
  whiteBg1,
  whiteBg2,
  whiteBg3,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteNeutral2,
  text2Neutral1,
  text2Bg2,
  whiteError,
  whiteNeutral1,
  text1Bg4,
  primaryBg1,
  text3White,
  text2Bg3,
  whiteText3,
});

export const colorCombinations61200 = getColorCombinations(
  colorTypes61200,
  pick
);

export const JSSThemeVariables61200: JSSThemeVariablesShapeType = {
  colors: {
    colorTypes: colorTypes61200,
    colorCombinations: colorCombinations61200,
  },
  fonts: {
    default: {
      name: 'osg-mont',
      fontFamily: 'osg-mont, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'mont/mont-book-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'mont/mont-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
};

export const JSSTheme61300: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { whiteBg1, whiteNeutral1, text4 },
      colorTypes: {
        background1,
        background3,
        primary,
        text2,
        black,
        white,
        neutral1,
        text5,
        background6,
        background4,
      },
    },
    fontSizes,
    margins,
    borderRadius,
  } = themeVars;
  return {
    Heading: {
      common: {
        color: text4,
        padding: [margins.md, margins.xm, '0'],
      },
    },
    CookiesBanner: {
      CookiesBannerButtonAccept: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
      CookiesBannerButtonDecline: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
    },
    CookiesBannerMobile: {
      CookiesBannerButtonAccept: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
      CookiesBannerButtonDecline: {
        color: black,
        '&:hover': {
          color: black,
        },
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: background1,
      },
      textGeoblock: {
        color: white,
      },
    },
    mybets: JSSThemeMyBets61300(themeVars),
    header: JSSThemeHeader61300(themeVars),
    headerMenu: JSSThemeHeaderMenu61300(themeVars),
    Button: {
      root: {
        lineHeight: '26px',
      },
      default: {
        color: black,
        background: primary,
        '&:hover': {
          color: white,
          background: background6,
        },
        '&:disabled': {
          '&:hover, &:active': {
            color: black,
            background: background4,
          },
        },
      },
      primary: {
        color: black,
        background: primary,
        '&:hover': {
          color: black,
          background: text5,
        },
        '&:disabled': {
          '&:hover, &:active': {
            color: black,
            background: background4,
          },
        },
      },
      secondary: getButtonColors(whiteNeutral1),
      accent: {
        color: black,
        background: primary,
        '&:hover': {
          color: black,
          background: text5,
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkSecondary: {
        color: black,
        textDecoration: 'none',
        ...applyOnlyDesktop({
          '&:hover': {
            color: black,
            textDecoration: 'underline',
          },
        }),
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
            opacity: 0.7,
          },
        }),
      },
      linkDefault: {
        color: white,
        '@media not all and (pointer: coarse)': {
          //applyOnlyDesktop
          '&:hover, &:focus, &:active': {
            color: white,
          },
        },
      },
    },
    betslip: betslip(themeVars),
    LiveCalendar: {
      h2: {
        ...whiteBg1,
        fontWeight: 'bold',
        padding: [0, margins.md],
      },
      dateTitle: {
        background: background3,
        borderBottom: `1px solid ${background1}`,
        padding: [margins.sm, margins.md],
      },
      dateEvent: {
        background: background1,
        borderBottom: `1px solid ${background3}`,
        padding: [margins.sm, margins.md],
        '&:hover': {
          background: background3,
        },
      },
    },
    IconCircle: {
      root: {
        background: primary,
      },
    },
    NoBetsMessage: {
      root: {
        background: background1,
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `inset 0px -1px ${black}`,
        background: 'transparent',
        color: white,
      },
      title: {
        lineHeight: `${margins.xl}px`,
        fontWeight: 'normal',
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakeTitle: {
        fontWeight: 'normal',
        marginBottom: margins.md,
        marginTop: 0,
        fontSize: fontSizes.sm,
      },
      money: {
        fontSize: fontSizes.md,
        color: white,
      },
      stakePotentialWrapper: {
        marginTop: margins.xs,
        marginBottom: margins.sm,
        background: neutral1,
        padding: [margins.xs, margins.md],
        borderTop: `none`,
        borderRadius: borderRadius.md,
      },
      stakePotential: {
        color: text2,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        ...whiteBg1,
        fontSize: fontSizes.sm,
        margin: 0,
      },
    },
    OfflineMessage: {
      popup: {
        color: 'black',
      },
    },
    banners: JSSThemeBannerItems61300(themeVars),
    sportsMenu: JSSThemeSportsMenu61300(themeVars),
    form: JSSThemeForm61300(themeVars),
    tooltip: JSSThemeTooltip61300(themeVars),
    bonuses: JSSThemeBonuses61300(themeVars),
    headerMobile: JSSThemeHeaderMobile61300(themeVars),
    search: JSSThemeSearch61300(themeVars),
    eventSlider: JSSThemeEventSlider61300(themeVars),
    outcomes: JSSThemeOutcomes61300(themeVars),
    coupons: JSSThemeCoupons61300(themeVars),
    balance: JSSThemeBalance61300(themeVars),
    live: JSSThemeLive61300(themeVars),
    authForm: JSSThemeAuthForm61300(themeVars),
    popularLeagues: JSSThemePopularLeagues61300(themeVars),
    accountSection: JSSThemeAccountSection61300(themeVars),
    promotions: JSSThemePromotions61300(themeVars),
    sitemap: JSSThemeSitemap61300(themeVars),
    resets: JSSTheme61200Resets(themeVars),
    casino: JSSThemeCasino61300(themeVars),
    footerAll: JSSThemeFooterAll61300(themeVars),
    dropdownMenu: JSSThemeDropdownMenu61300(themeVars),
    // affiliates: JSSThemeAffiliates61300(themeVars),
    cookies: JSSThemeCookies61300(themeVars),
  };
};

export const getJSSThemePatterns61200: GetJSSThemePatternsType = (
  themeVars
) => {
  const {
    colors: {
      colorCombinations: { text2Bg2, whitePrimary, whiteAccent },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: getButtonColors(whiteAccent),
      secondary: getButtonColors(text2Bg2),
      accent: getButtonColors(whitePrimary),
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
