// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions61300: JSSThemeDefaultPromotionsType = themeVars => {
  const { margins } = themeVars;
  return {
    SeasonalPromotion: {
      promoHeading: {
        padding: 0,
        position: 'relative',
      },
      buttonRoot: {
        background: 'transparent',
        position: 'absolute',
        top: 70,
      },
      img: {
        objectPosition: 'right',
      },
      root: {
        padding: ['18px', margins.md],
      },
    },
  };
};
