// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection61300: JSSThemeDefaultAccountSectionType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background3, black },
    },
  } = themeVars;
  return {
    BetsHistory: {
      filterSelectionItem: {
        color: black,
      },
    },
    OPMProvider: {
      providerRoot: {
        background: background3,
        border: '1px solid #223553',
        boxShadow: 'none',
        maxWidth: 170,
      },
      logoImg: {
        marginBottom: 10,
        opacity: '.9',
      },
    },
    ResponsibleGaming: {
      changeLimitsButtonDiv: {
        '@media (pointer: coarse)': {
          marginRight: '70px',
        },
      },
    },
  };
};
