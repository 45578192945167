// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll61300: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, background1 },
    },
  } = themeVars;
  return {
    footerWrap: {
      background: background1,
      color: white,
    },
    socialHide: {
      display: 'none',
    },
    socialWrap: {
      display: 'none',
    },
    socialCont: {
      margin: 0,
    },
    menuWrapper: {
      width: '60%',
      paddingBottom: 0,
    },
    licenseLink: {
      paddingRight: 0,
    },
  };
};
