// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons61300: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    borderRadius,
    colors: {
      colorTypes: { background1, text4, primary, black, neutral1, white },
    },
    margins,
  } = themeVars;
  return {
    MobileWidgetCouponView: {
      sportMobileWrap: {
        background: background1,
      },
      sportHeader: {
        color: primary,
      },
    },
    SportTabs: {
      tabs: {
        background: neutral1,
        padding: 0,
      },
      tab: {
        background: neutral1,
        color: white,
        borderRadius: 0,
        marginRight: 0,
        padding: margins.md,
        '&:hover': {
          color: primary,
        },
      },
      activeTab: {
        background: background1,
        color: primary,
        borderRadius: [borderRadius.xl, borderRadius.xl, 0, 0],
        '&:focus': {
          color: primary,
        },
        '&:focus, &:hover': {
          color: primary,
        },
      },
    },
    AllLeagues: {
      root: { backgroundColor: background1 },
    },
    SuspendedBlock: {
      suspendedBlock: {
        background: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
      },
    },
    Market: {
      root: {
        padding: [margins.xs, 0],
        borderRadius: 0,
        borderTop: 'none',
        background: background1,
      },
      date: {
        color: text4,
      },
    },
    WidgetSport: {
      event: {
        margin: [margins.md, 0],
        background: background1,
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        color: primary,
        fontWeight: 'bold',
        '&:hover': {
          color: '#fff',
        },
      },
    },
    EventCoupon: {
      eventHeader: {},
    },
    Betbuilder: {
      toggler: {
        color: black,
        '&:hover': {
          background: primary,
        },
      },
      exampleContainer: {
        background: '#0a1724',
      },
    },
    PrematchEvent: {
      eventDescription: {
        width: '100% !important',
      },
      betbuilderMark: {
        color: black,
      },
    },
  };
};
