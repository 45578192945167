// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino61300: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { white, text5, background1, black, primary },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: background1,
      },
      menuItem: {
        color: white,
        borderRight: 'none',
        '&.active, &:hover': {
          color: black,
          background: primary,
        },
        ...applyOnlyDesktop({
          '&:hover': {
            color: black,
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: black,
          },
        }),
      },
      iconChevron: {
        marginLeft: margins.xs,
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    CasinoGameLink: {
      rootReal: {
        color: black,
        background: text5,
        '&.active, &:hover': {
          color: black,
          background: primary,
          opacity: 0.9,
        },
      },
      rootFun: {
        background: white,
        color: black,
        '&.active, &:hover': {
          background: white,
          color: black,
          opacity: 0.9,
        },
      },
    },
    SearchBox: {
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
      },
      inputSearch: {
        height: 32,

        borderBottom: '1px solid rgba(250,250,250,0.3)',
        '&::placeholder': {
          opacity: 0.5,
        },
      },
      iconSearch: {
        opacity: 0.5,
      },
      searchResult: {
        padding: [0, margins.md],
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        background: background1,
      },
      providersFilters: {
        '&.active': {
          color: black,
          background: primary,
          opacity: 1,
          transition: 'all 0.2s ease',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            color: black,
            background: primary,
            opacity: 1,
            transition: 'all 0.2s ease',
          },
        }),
      },
    },
  };
};
