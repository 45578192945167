// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61300: JSSThemeDefaultAuthFormType = themeVars => {
  const {
    margins,
    borderRadius,
    colors: {
      colorTypes: { background5 },
    },
  } = themeVars;
  return {
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: background5,
        borderRadius: borderRadius.md,
        marginRight: margins.xs,
      },
    },
  };
};
