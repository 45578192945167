// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeCookies61300 = (themeVars: typeof JSSThemeVariables) => {
  const {} = themeVars;
  return {
    cookiesPolicy: {
      CookiesPolicyCell: {
        '@media screen and (max-width: 420px)': {
          wordBreak: 'break-all',
        },
      },
    },
  };
};
