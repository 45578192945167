// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm61300: JSSThemeDefaultFormType = themeVars => {
  const {
    colors: {
      colorCombinations: { text2Neutral1 },
      colorTypes: { text2, shadow, border1, background4, background3, neutral1 },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderColor: 'transparent',
        color: text2,
        background: shadow,
        '&::placeholder': {
          color: border1,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${background3} inset !important`,
          borderColor: background3,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
      betslipInput: {
        height: '30px',
        lineHeight: '30px',
        border: 'none',
        borderRadius: borderRadius.md,
        ...text2Neutral1,
        '&::placeholder': {
          color: background4,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${neutral1} inset !important`,
          borderColor: `${neutral1} !important`,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
    },
  };
};
