// @flow
import { type JSSThemeDefaultBonusesType } from '../JSSThemeDefaultBonuses';

export const JSSThemeBonuses61300: JSSThemeDefaultBonusesType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { neutral1, background3, primary, black, white },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        background: background3,
        color: white,
        '&.active, &:hover': {
          background: primary,
          color: black,
        },
      },
      tabsItemMobile: {
        background: background3,
        color: white,
        borderRight: `1px solid ${neutral1}`,
        '&.active': {
          background: primary,
          color: black,
        },
      },
      bonusContainer: {
        background: 'transparent',
        border: 'none',
        padding: [margins.md, 0],
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
    },
    Bonuses: {
      root: {
        background: background3,
        border: 'none',
      },
    },
  };
};
