// @flow
import React from 'react';

import { ModalBox } from './ModalBox';

/**
 * Modal window
 * @param {number} remaining - timer before auto logout
 * @param {() => void} handleStillHere - callback if user click to continue session
 * @param {() => void} unauthorize - callback if user click to logout
 * @returns UI, React.Component
 */
// FIXME: UI can differ from that
export const IdleAutoLogoutMessage = ({
  remaining,
  handleStillHere,
  unauthorize,
}: {
  remaining: number,
  handleStillHere: () => void,
  unauthorize: ({ forced: boolean }) => void,
}) => {
  const buttons = [
    {
      id: 'still-here',
      action: () => {
        handleStillHere();
      },
      title: _t('session-timeout-before-logout-btn-still-here'),
      type: 'button',
    },
    {
      id: 'logout',
      action: () => {
        unauthorize({ forced: false });
      },
      title: _t('session-timeout-before-logout-btn-logout'),
      type: 'button',
    },
  ];

  return (
    <ModalBox
      visible
      title={_t('session-timeout-before-logout-title')}
      buttons={buttons}
      gaProps={{
        style: {
          width: '500px',
          height: '212px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '10px',
          lineHeight: '40px',
        },
      }}
    >
      {_t('session-timeout-before-logout-text')} {remaining}
    </ModalBox>
  );
};
