// @flow
import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import axios from 'axios';

import { IdleAutoLogoutMessage } from './IdleAutoLogoutMessage';
import { getUserToken } from '../../koltron-redux/selectors/auth';
import { useReduxState } from '../../redux-hooks';
import { useReduxDispatch } from '../../redux-hooks';
import { API_LOGOUT, VALIDATE_TOKEN } from '../../../common/constants';
import {
  KOLTRON_UNAUTHORIZE,
  KOLTRON_UNAUTHORIZE_WITH_ERROR,
} from '../../koltron-redux/actions/action-types';

/**
 * UX for Modal window before auto logout.
 * Counts user's idle time and shows prompt modal window with user warning before timer is reached.
 * In case of user not manually prolonging their session, initiates auto logout.
 * @returns UI, React.Component
 */
export const IdleAutoLogout = () => {
  const soonLogout = __OSG_CONFIG__.idleTimer.soonLogout;

  const token = useReduxState(getUserToken);
  const dispatch = useReduxDispatch();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const idleTimeInMinutes = __OSG_CONFIG__.idleTimer.timeout;
  const timeout = idleTimeInMinutes * 60 * 1000;

  /** Modal window appear one minutes before logout */
  const promptBeforeIdle = 1 * 60 * 1000;

  const [remaining, setRemaining] = useState<number>(timeout / 1000);

  /* TODO: move cleaning of localStorage variables to reducer to not to duplicate it everytime */
  const unauthorize = React.useCallback(
    ({ forced }: { forced: boolean }) => {
      axios.get(API_LOGOUT).then(() => {
        if (forced) {
          dispatch({
            type: KOLTRON_UNAUTHORIZE_WITH_ERROR,
          });
        } else {
          dispatch({
            type: KOLTRON_UNAUTHORIZE,
          });
        }
      });
    },
    [dispatch]
  );

  const handleOnIdle = () => {
    setOpenModal(false);
    unauthorize({ forced: true });
  };

  const onPrompt = () => setOpenModal(true);

  const handleOnActive = () => setOpenModal(false);

  const { getRemainingTime, activate } = useIdleTimer({
    /** Function to call when user is idle */
    onIdle: handleOnIdle,
    /** Function to call when user becomes active */
    onActive: handleOnActive,
    /**
     * When promptTimeout is set, this function is called after the user becomes idle.
     * This is useful for displaying a confirm prompt.
     * If the prompt timeout is reached, onIdle is then called
     * */
    onPrompt,
    /** Activity Timeout in milliseconds */
    timeout,
    /** The amount of milliseconds before idle timeout to call the onPrompt and onPresenceChange event handlers */
    promptBeforeIdle,
    /** Throttle the onAction function by setting delay in milliseconds */
    throttle: 500,
    /** Enable cross tab event replication */
    crossTab: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = Math.ceil(getRemainingTime() / 1000);
      setRemaining(remainingTime);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const handleStillHere = React.useCallback(() => {
    axios({
      url: VALIDATE_TOKEN,
      method: 'GET',
      params: {
        token: token,
      },
    })
      .then(({ data }) => {
        activate();
      })
      .catch(() => {
        // eslint-disable-next-line
        console.error('Error still here');
      });
  }, [token, activate]);

  return (
    <>
      {openModal && soonLogout && (
        <IdleAutoLogoutMessage
          handleStillHere={handleStillHere}
          unauthorize={unauthorize}
          remaining={remaining}
        />
      )}
    </>
  );
};
